<template>
  <div class="main-block how-to">
    <span class="how-to--title">{{ $t('nft-sale.how-title') }}</span>
    <div class="div"></div>
    <span class="how-to--description" v-html="$t('nft-sale.how-description') "></span>
    <img :src="require(`@/assets/img/nft-sale/how-to.png`)"/>
  </div>
</template>

<script>
export default {
  name: 'NftHowTo',
};
</script>

<style scoped lang="scss">
.how-to {
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding: 4rem 4rem 2rem 4rem;

  div {
    height: 1px;
    background: #A0A5D5;
    width: 100%;
    margin-top: 4rem;
    margin-bottom: 2.4rem;
  }

  &--title {
    font-size: 2.8rem;
    font-weight: 600;
  }

  &--description {
    font-style: normal;
    font-weight: 600;
    font-size: 1.8rem;
    margin-bottom: 3.6rem;
  }
  @media (max-width: $breakpoint-sm) {
    display: none;
  }
}
</style>
