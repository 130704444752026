<template>
  <div class="main-block">
    <v-row class="nft-form" align="center">
      <v-col class="col-12 col-md-6 d-flex justify-center nft-form__img">
        <img :src="require(`@/assets/img/nft-sale/card.png`)" class="nft-form card"/>
      </v-col>

      <v-col class="col-12 col-md-6 nft-form__container">
        <div class="main-block">
          <div class="nft-form__container--price">
            <span class="label">Price</span>
            <div>
              <strong>{{ price / 10**18 }}</strong>
              <div class="milk2-price">
                <img :src="require(`@/assets/img/milk-logo.svg`)" class="milk-icon"/>
                <span>MILK2</span>
              </div>
            </div>
          </div>
          <div class="nft-form__counter">
            <v-btn fab :disabled="quantity === 1" @click="quantity--">
              <v-icon>mdi-minus</v-icon>
            </v-btn>
            <div class="nft-form__quantity"> {{ quantity }} </div>
            <v-btn
              fab
              :disabled="(quantity >= limit - userTokensCount) || quantity >= left"
              @click="quantity++"
            >
              <v-icon>mdi-plus</v-icon>
            </v-btn>
          </div>

        </div>

        <div class="nft-form__container--info">
          <div class="card">Cards to buy <span>{{ quantity }} CARD(S)</span></div>
          <div class="price">
            <span>Total price </span>
            <div class="milk2-price">
              <img :src="require(`@/assets/img/milk-logo.svg`)" class="milk-icon"/>
              {{ amount.toFixed(2) }}
            </div>
          </div>
          <div class="card mt-3">You bought <span>{{ userTokensCount }} CARD(S)</span></div>
        </div>

        <v-btn
          v-if="checkAllowance"
          rounded
          block
          :disabled="activeDate"
          @click="approve"
          class="nft-form--approve btn"
          :loading="approveLoading"
        >
          Approve
        </v-btn>

        <v-btn
          v-else
          rounded
          block
          @click="register"
          class="nft-form--approve btn"
          :loading="buyLoading"
          :disabled="userTokensCount >= limit"
        >
          Buy
        </v-btn>
      </v-col>
    </v-row>
    <div class="presale-closed__content">
      Presale is closed
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'NftForm',

  props: {
    left: [Number, String],
    activeDate: Boolean,
  },

  data() {
    return {
      quantity: 1,
      approveLoading: false,
      buyLoading: false,
    };
  },

  computed: {
    ...mapGetters('Sidus', ['price', 'allowance', 'limit', 'userTokensCount', 'activeAfter']),

    amount() {
      return (this.price / 10 ** 18) * this.quantity;
    },

    amountForApprove() {
      return this.price * this.limit;
    },

    checkAllowance() {
      if (this.userTokensCount >= this.limit) {
        return false;
      }
      return (this.price * this.quantity > this.allowance);
    },
  },

  methods: {
    ...mapActions({
      approveMilk: 'Sidus/approveMilk2',
      registerForPreSale: 'Sidus/registerForPreSale',
      maxTotalSupply: 'Sidus/maxTotalSupply',
    }),

    async register() {
      try {
        this.buyLoading = true;
        await this.maxTotalSupply();
        if (this.left >= this.quantity) {
          await this.registerForPreSale(this.quantity);
          this.quantity = 1;
        } else {
          this.$notify({
            group: 'info',
            title: 'Max Total Supply limit exceeded',
          });
        }
        this.buyLoading = false;
      } catch (e) {
        this.buyLoading = false;
        console.log(e);
      }
    },

    async approve() {
      try {
        this.approveLoading = true;
        await this.approveMilk(this.amountForApprove);
        this.approveLoading = false;
      } catch (e) {
        this.approveLoading = false;
        console.log(e);
      }
    },
  },
};
</script>

<style scoped lang="scss">
.nft-form {
  padding: 2rem 3rem;
  &__img{
    transform-style: preserve-3d;
    perspective: 600px;
    img{
      -webkit-animation: rotate 5s infinite;
      animation: rotate 5s infinite;
    }
  }
  @keyframes rotate {
    0% {
      transform: rotateY(20deg);
    }
    25% {
      transform: rotateY(0deg);
    }
    50% {
      transform: rotateY(-20deg);
    }
    75% {
      transform: rotateY(0deg);
    }
    100% {
      transform: rotateY(20deg)
    }
  }
  &__quantity{
    font-weight: bold;
    font-size: 2.5rem;
    line-height: 3rem;
    color: #283287;
    user-select: none;
    body.binance &{
      color: white;
    }
  }

  &__counter{
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: linear-gradient(103.94deg, #FDFAFB -1.71%, #F7FBFC 45.51%);
    box-shadow: 5px 5px 10px rgba(255, 255, 255, 0.9),
      -1px -1px 5px rgba(182, 169, 173, 0.3),
      inset -2px -2px 2px rgba(182, 169, 173, 0.2),
      inset 1px 1px 4px rgba(182, 169, 173, 0.2),
      inset -2px -2px 4px rgba(255, 255, 255, 0.9),
      inset 1px 1px 2px rgba(182, 169, 173, 0.2);
    border-radius: 9.5rem;
    height: 7rem;
    padding: 0.5rem;
    box-sizing: border-box;

    .v-btn{
      height: 6.2rem;
      width: 6.2rem;
      box-sizing: border-box;
      background: #F8FCFD;
      color: #283287;
      box-shadow: 10px 10px 20px rgba(222, 222, 222, 0.25), 2px 2px 20px rgba(212, 202, 205, 0.2),
        -10px -10px 15px #FFFFFF, 10px 10px 20px rgba(181, 155, 155, 0.25),
        inset 2px 2px 6px #FFFFFF, inset -1px -1px 2px rgba(181, 155, 155, 0.2);
      &--disabled{
        background: linear-gradient(103.94deg, #FDFAFB -1.71%, #F7FBFC 45.51%);
      }

      body.binance &{
        background: linear-gradient(180deg, #FFE55B 0%, #DEBE17 100%);
        box-shadow: 2px 2px 20px #090808, 2px 2px 20px rgb(10 9 8 / 20%),
          -4px -4px 10px #5c5a52, 6px 6px 14px rgb(44 41 30 / 64%),
          inset 2px 2px 6px #f4eab4, inset -1px -1px 3px rgb(255 242 128 / 73%);
        color: #0F1929;
      }
    }

    body.binance &{
      background: linear-gradient(180deg, #1A1A20 0%, #181822 100%);
      box-shadow: 5px 5px 15px #26262f, -2px -2px 20px #23232d,
        inset -2px -2px 10px rgb(22 22 26 / 60%), inset 2px 2px 20px rgb(23 24 33 / 20%),
        inset -2px -2px 6px #171724, inset -4px -4px 20px rgb(0 0 0 / 6%);
    }
  }

  &--card {
    width: 100%;
    max-height: 40rem;
    object-fit: contain;
  }

  &--approve {
    margin: 2rem 0 0 0;
    background-color: #F8FCFD !important;
    height: 6rem !important;
    box-shadow: 10px 10px 20px rgba(222, 222, 222, 0.25), 2px 2px 20px rgba(212, 202, 205, 0.2),
      -10px -10px 15px #FFFFFF, 10px 10px 20px rgba(181, 155, 155, 0.25),
      inset 2px 2px 6px #FFFFFF, inset -1px -1px 2px rgba(181, 155, 155, 0.2);
    font-weight: bold;
    font-size: 1.6rem;
    line-height: 140%;
    color: #343E9A;
    body.avalanche &{
      background: linear-gradient(180deg, #FD9595 0%, #FB5051 100%);
      box-shadow: 10px 10px 20px rgb(244 177 177 / 25%), 2px 2px 20px rgb(159 87 87 / 20%),
      -10px -10px 15px #f7dede, 10px 10px 20px rgb(220 153 153 / 25%),
      inset 2px 2px 6px #fcaeae, inset -1px -1px 2px rgb(217 165 165 / 20%);
    }
  }

  &__container {
    max-width: 50rem;
    margin: 0 auto;

    &--info {
      display: flex;
      flex-direction: column;
      flex: 1;

      div {
        display: flex;
        justify-content: space-between;
      }

      .price {
        font-size: 2.4rem;
        font-weight: bold;
        margin-bottom: 0.5rem;
      }

      .card {
        font-size: 1.6rem;

        span {
          font-weight: 500;
        }
      }
    }

    .main-block {
      padding: 1.5rem;
      margin-bottom: 3.2rem;
    }

    &--price {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 1rem;

      .label {
        font-size: 2.4rem;
        font-weight: 600;
      }

      strong {
        font-size: 2.8rem;
        font-weight: 600;
      }

      span {
        font-weight: 500;
        font-size: 1.8rem;
      }
    }

    .input-container {
      margin-bottom: 0.5rem !important;
    }
  }
  @media (max-width: $breakpoint-sm) {
    &__img img{
      width: 100%;
      max-width: 360px;
    }
  }
}

.milk-icon {
  width: 1.8rem;
  height: 1.8rem;
  display: inline;
  margin-right: 0.7rem;
}
.main-block{
  user-select: none;
}
.milk2-price {
  display: flex;
  align-items: center;
}
</style>
