<template>
  <div class="main-block nft-timer">
    <span class="nft-timer--title">{{ title }}</span>
    <div class="nft-timer__container">
      <div class="nft-timer__container--time">
        <strong>{{ countdown.d }}</strong>
        <span>Days</span>
      </div>
      <span class="nft-timer__container--div">:</span>
      <div class="nft-timer__container--time">
        <strong>{{ countdown.h }}</strong>
        <span>Hours</span>
      </div>
      <span class="nft-timer__container--div">:</span>
      <div class="nft-timer__container--time">
        <strong>{{ countdown.m }}</strong>
        <span>minutes</span>
      </div>
      <span class="nft-timer__container--div">:</span>
      <div class="nft-timer__container--time">
        <strong>{{ countdown.s }}</strong>
        <span>Seconds</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'NftTimer',
  props: {
    title: {
      type: String,
      default: 'Starts in:',
    },
    date: {
      type: [String, Number],
      default: '03.03.2022 04:45:00',
    },
  },
  data() {
    return {
      now: Date.now(),
    };
  },
  mounted() {
    window.setInterval(() => {
      this.now = new Date();
    }, 1000);
  },
  methods: {
    formatTime(time) {
      if (time < 10) {
        return `0${time}`;
      }

      return time;
    },
  },
  computed: {
    countdown() {
      const { now } = this;
      const date = new Date(this.date * 1000);
      const diff = date - now > 0 ? date - now : 0;
      const days = Math.floor(diff / 1000 / 3600 / 24);
      const hours = Math.floor((diff / 1000 / 3600) % 24);
      const minutes = Math.floor((diff / 1000 / 60) % 60);
      const seconds = Math.floor((diff / 1000) % 60);
      this.$emit('end', this.date * 1000 > Date.now());
      return {
        d: this.formatTime(days),
        h: this.formatTime(hours),
        m: this.formatTime(minutes),
        s: this.formatTime(seconds),
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.nft-timer {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1.4rem;

  &--title {
    font-style: normal;
    font-weight: 600;
    font-size: 2.6rem;
    margin-top: 0.3rem;
    line-height: 3rem;
  }

  &__container {
    display: flex;
    justify-content: space-between;
    flex: 1;
    margin: 0.1rem 0;

    &--div {
      font-size: 4.8rem;
      font-weight: bold;
    }

    &--time {
      display: flex;
      flex-direction: column;
      margin: 0 2rem;
      text-align: center;
      align-items: center;

      strong {
        font-size: 4.8rem;
      }

      span {
        color: #7478A0;
        font-size: 1.2rem;
        text-transform: uppercase;
        font-style: normal;
        font-weight: 600;
      }
    }
  }
}
</style>
