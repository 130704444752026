<template>
  <v-col class="main-wrap main-wrap--narrow booster">
    <div class="page-loader" v-if="loading">
      <v-progress-circular
        :size="100"
        color="primary"
        indeterminate
      ></v-progress-circular>
    </div>
    <div class="page-content" v-else>
      <h1 class="page-title">{{ $t('nft-sale.title') }}</h1>
      <div class="limit-info">
      <span>{{ $t('nft-sale.limit', {limit}) }}</span>
      </div>
      <v-row class="page-content" align="stretch">
        <v-col class="col-12 col-md-6" v-if="left > 0 && active">
          <NftTimer :date="activeAfter" @end="active = $event" />
        </v-col>
        <v-col
          class="col-12"
          :class="{ 'col-md-6' : active }"
          v-if="left > 0 && close"
        >
          <NftTimer title="Ends in:" :date="closedAfter" @end="close = $event"/>
        </v-col>

        <v-col class="col-12" v-if="!closed">
          <div class="main-block cards-info">
            <span class="data" v-if="supply">
              {{ left | amount }}
            </span>
            <span class="label">{{ $t('nft-sale.cards-left') }}</span>
          </div>
        </v-col>

        <v-col class="col-12 mt-4">
          <NftForm
            :class="{ 'presale-closed': closed }"
            :left="left"
            :activeDate="active"
          />
        </v-col>

        <v-col class="col-12 mt-4">
          <NftHowTo/>
        </v-col>
      </v-row>
    </div>
  </v-col>
</template>

<script>
import NftTimer from '@/components/NftSale/NftTimer';
import NftForm from '@/components/NftSale/NftForm';
import NftHowTo from '@/components/NftSale/NftHowTo';
import { mapGetters } from 'vuex';

export default {
  name: 'nft-sale',
  components: { NftHowTo, NftForm, NftTimer },

  data() {
    return {
      loading: true,
      active: true,
      close: true,
    };
  },

  computed: {
    ...mapGetters('Sidus', ['supply', 'closedAfter', 'activeAfter', 'limit']),
    left() {
      if (!this.supply) return null;
      return this.supply.maxTotalSupply - this.supply.currentSupply;
    },

    closed() {
      return (!this.close || this.left <= 0);
    },
  },

  async mounted() {
    setTimeout(async () => {
      await this.$store.dispatch('Sidus/getPresaleInfo');
      this.loading = false;
    }, 2000);

    setInterval(async () => {
      await this.$store.dispatch('Sidus/getPresaleInfo');
    }, 5000);

    window.ethereum.on('chainChanged', () => {
      window.location.reload();
    });
  },
};
</script>

<style lang="scss">
.page-loader{
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 60rem;
}
.presale-closed{
  position: relative;
  overflow: hidden;
  &__content{
    display: none;
  }
  & &__content{
    background: rgba(255, 255, 255, 0.5);
    backdrop-filter: blur(70px);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    font-size: 6.4rem;
    line-height: 7.8rem;
    letter-spacing: 0.04em;
    text-transform: uppercase;
    text-align: center;
    padding-left: 2rem;
    padding-right: 2rem;
    color: #283287;
  }
}

.limit-info {
  font-size: 2rem;
  text-align: center;
  display: flex;
  justify-content: center;
  width: 100%;
}

.cards-info {
  display: flex;
  justify-content: center;
  flex: 1;
  flex-direction: column;
  align-items: center;
  padding: 2.4rem 0;

  .data {
    margin-bottom: 1.2rem;
    font-size: 3.8rem;
    font-weight: 600;
  }

  .label {
    font-size: 2rem;
    font-weight: 500;
  }
}
</style>
